<template>
  <div class="radio-input">
    <wraper :title="title" class="wraper" :isRequred="isRequred">
      <div class="flexwrapbetween wraper ">
        <div
          class=""
          style="minWidth: 50%;"
          @click="onChange(item.id)"
          v-for="item in items"
          :key="item.id"
        >
          <radio-thumb
            :title="item.optionContent"
            :isChecked="checked.indexOf(item.id) != -1"
          />
        </div>
        <!-- <van-radio-group v-model="checked" direction="horizontal">
          <van-radio name="1">
            单选框 1
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? activeIcon : inactiveIcon"
              />
            </template>
          </van-radio>
          <van-radio name="2">
            单选框 2
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? activeIcon : inactiveIcon"
              />
            </template>
          </van-radio>
        </van-radio-group> -->
      </div>
    </wraper>
  </div>
</template>

<!-- <template>
  <div class="checkbox-input">
    <wraper :title="title" class="wraper" :isRequred="isRequred">
      <div class="flexrowstart wraper ">
        <van-checkbox-group
          v-model="checked"
          @change="onChange"
          direction="horizontal"
          
        >
          <div class="checkbox" style="minWidth: 50%;"  v-for="item in items" :key="item.id">
            <van-checkbox
              :name="item.id"
              checked-color="#12beae"
              icon-size="16"
            >
              {{ item.optionContent }}</van-checkbox
            >
            <div class="height24"></div>
          </div>
        </van-checkbox-group>
      </div>
    </wraper>
  </div>
</template> -->

<script>
import Wraper from "@/components/input/wraper.vue";
import RadioThumb from "@/components/input/radio-thumb.vue";
import { Checkbox, CheckboxGroup } from "vant";

export default {
  props: {
    title: String,
    items: Array,
    index: Number,
    isRequred: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  methods: {
    onChange(id) {
      var index = this.checked.indexOf(id)
      if(index == -1) {
        this.checked.push(id)
      } else {
        this.checked.splice(index, 1);
      }
      console.log(`checked:${JSON.stringify(this.checked)}`);
      this.$emit("change", { value: this.checked, index: this.index });
    },

    //  onChange(id) {
    //   console.log(`checked`);

    //   this.checked = id;
    //   this.$emit("change", { value: [id], index: this.index });
    // },
  },
  components: {
    Wraper,
    Checkbox,
    CheckboxGroup,
    RadioThumb
  },
};
</script>

<style lang="scss" scoped>
.radio-input {
  .wraper {
    padding: 24px 28px 0px;
  }
}


</style>
