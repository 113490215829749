<template>
  <div class="date-input">
    <wraper :title="title" :isRequred="isRequred">
      <div class="flexrowstart row">
        <img src="@/assets/img/ic_riqi@2x.png" alt="" class="icon" />
        <span v-if="value && value.length > 0" class="text">{{ value }}</span>
        <span v-else class="placeholder">{{ placeholder }}</span>
      </div>
    </wraper>
  </div>
</template>

<script>
import Wraper from "@/components/input/wraper.vue";
import { DatetimePicker } from "vant";
export default {
  props: {
    value: String,
    title: String,
    index: Number,
    isRequred: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
  },
  data() {
    return { currentDate: true, show: true };
  },
  components: { Wraper, DatetimePicker },
};
</script>

<style lang="scss" scoped>
.date-input {
  .row {
    margin-top: 24px;
    margin-left: 24px;
    .icon {
      object-fit: contain;
      width: 44px;
      height: 41px;
      margin-right: 32px;
    }
    .text {
      font-size: 30px;
      // margin-top: 26px;
      // margin-left: 24px;
      //     background: red;
      height: 42px;
    }
    .placeholder {
      font-size: 30px;
      font-weight: 400 !important;
      color: #9c9fad;
      line-height: 45px;
    }
  }
}
</style>
