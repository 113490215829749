<template>
  <div class="submit-btn flexrowcenter" @click="onClick">
    <div class="btn flexrowcenter">
      {{ title || "确定" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    onClick() {
      this.$emit("click", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  align-items: center;
  width: 750px;
  height: 136px;
  background: #ffffff;
  // box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(203, 203, 203, 0.5);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .btn {
    width: 694px;
    height: 94px;
    background: #12beae;
    border-radius: 24px;
    font-size: 36px;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    align-items: center;
  }
}
</style>
