<template>
  <div class="flexcolumnstart wraper ">
    <div class="flexrowstart title-wraper ">
      <span class="required">{{ isRequred ? "*" : "" }}</span>
      <span class="title">{{ title }}</span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    isRequred: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wraper {
  padding: 24px 28px;
  box-sizing: border-box;
  background: white;
  .title-wraper {
    align-items: start;
    .required {
      width: 17px;
      height: 45px;
      font-size: 32px;
      font-weight: 500;
      color: #f76060;
      line-height: 45px;
      padding-right: 8px;
    }
    .title {
      font-size: 30px;
      font-weight: 400;
      color: #040814;
      line-height: 45px;
      border-bottom: 1px solid #eef1f5;
      padding-bottom: 24px;
      flex: 1;
    }
  }
}
</style>
