<template>
  <div class="radio-thumb-root">
    <div
      v-if="readOnly"
      
    > 
      
      <img  v-if="isChecked" class="radio-img" src="https://sho-static.shulan.com/minipro/agreementselect.png">

      <div v-else  class="radio-read-only-unselect radio" /> 


      
    
    </div>

    <!-- :class="isChecked == true ? 'radio radio-select' : 'radio radio-unselect'" -->
    <div
      v-else
      
    >
     <img  v-if="isChecked" class="radio-img" src="https://sho-static.shulan.com/minipro/agreementselect.png">

      <div v-else class="radio radio-unselect" /> 

    </div>
    <span style="white-space: pre;" class="title" :style="readOnly ? 'color:#9C9FAD;' : ''">{{title}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },

    isChecked: {
      type: Boolean,
      value: false,
    },
    readOnly: {
      type: Boolean,
      value: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-thumb-root {
  display: flex;

  align-items: flex-start;
  // line-height: 32px;
  padding: 0px 25px 25px 4px;
  height: auto;
}

.radio {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #30b5a9;
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 24px;
  margin-top: 5px;
}

.radio-img {
  width: 37px;
  height: 37px;
  display: block;
  margin-right: 24px;
  margin-top: 5px;
}

.radio-select {
  border: 8px solid #30b5a9;
}
.radio-unselect {
  border: 2px solid #30b5a9;
}

.radio-read-only-select {
  border: 8px solid #cdcfd8;
}
.radio-read-only-unselect {
  border: 16px solid #cdcfd8;
}

.title {
  // height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #040814;
  line-height: 47px;
}
</style>
