<template>
  <div class="radio-input">
    <wraper :title="title" class="wraper" :isRequred="isRequred">
      <div class="flexwrapbetween wraper ">
        <div
          class=""
          style="minWidth: 50%;"
          @click="onChange(item.id)"
          v-for="item in items"
          :key="item.id"
        >
          <radio-thumb
   
            :title="item.optionContent"
            :isChecked="item.id == checked"
          />
        </div>
        <!-- <van-radio-group v-model="checked" direction="horizontal">
          <van-radio name="1">
            单选框 1
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? activeIcon : inactiveIcon"
              />
            </template>
          </van-radio>
          <van-radio name="2">
            单选框 2
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? activeIcon : inactiveIcon"
              />
            </template>
          </van-radio>
        </van-radio-group> -->
      </div>
    </wraper>
  </div>
</template>

<script>
import RadioThumb from "@/components/input/radio-thumb.vue";
import { RadioGroup, Radio } from "vant";
import Wraper from "@/components/input/wraper.vue";
export default {
  props: {
    title: String,
    items: Array,
    index: Number,
    isRequred: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checked: 0,
    };
  },
  methods: {
    onChange(id) {
      console.log(`checked`);

      this.checked = id;
      this.$emit("change", { value: [id], index: this.index });
    },
  },
  components: {
    RadioThumb,
    Wraper,
    RadioGroup,
    Radio,
  },
};
</script>

<style lang="scss" scoped>
.radio-input {
  .wraper {
    padding: 24px 28px 0px;
  }
}
</style>
