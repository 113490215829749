<template>
  <div class="text-input">
    <wraper :title="title" :isRequred="isRequred">
      <input
        @input="textOnChange"
        class="input"
        :placeholder="placeholder"
        v-model="text"
      />
    </wraper>
  </div>
</template>

<script>
import wraper from "@/components/input/wraper.vue";

export default {
  props: {
    title: String,
    index: Number,
    isRequred: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    value: String,
    field: String,
  },
  data() {
    return { text: "" };
  },
  components: { wraper },
  methods: {
    textOnChange(e) {
      this.$emit("change", {
        value: this.text,
        index: this.index,
        field: this.field,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  .input {
    font-size: 30px;
    margin-top: 26px;
    margin-left: 24px;
    //     background: red;
    height: 42px;
    &::placeholder {
      color: #9c9fad;
    }
  }
}
</style>
